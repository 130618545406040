<template>
  <div>
    <Divider dashed style="color:#fff">设置临时任务内容</Divider>
    <Form
      ref="newTaskData"
      :model="newTaskData"
      :rules="ruleValidate"
      label-position="top"
    >
      <FormItem
        label="作业日期"
        prop="actionDate"
      >
        <DatePicker
          size="small"
          placeholder="选择作业日期"
          v-model="newTaskData.actionDate"
          type="date"
          style="width:100%"
          :editable="false"
          :clearable="false"
          @on-change="changeActionDate"
        ></DatePicker>
      </FormItem>
      <FormItem
        label="选择资产"
        prop="assetId"
      >
        <Select
          v-model="newTaskData.assetId"
          size="small"
          placeholder="资产"
          @on-change="getStationData"
        >
          <Option
            v-for="item in companyAssetArray"
            :key="'asset_'+item.id"
            :value="item.id"
          >{{item.name}}</Option>
        </Select>
      </FormItem>
      <FormItem
        label="选择站点"
        prop="domainId"
      >
        <Select
          v-model="newTaskData.domainId"
          size="small"
          placeholder="选择站点"
          :clearable="true"
        >
          <Option
            v-for="item in stationArray"
            :key="'station_'+item.id"
            :value="item.id"
          >{{item.name}}</Option>
        </Select>
      </FormItem>
      <FormItem
        label="任务标题"
        prop="title"
      >
        <Input
          v-model.trim="newTaskData.title"
          size="small"
          placeholder="任务标题"
        />
      </FormItem>
      <FormItem label="详细描述">
        <Input
          type="textarea"
          size="small"
          :rows="3"
          v-model.trim="newTaskData.remarks"
          placeholder="详细描述"
        />
      </FormItem>
      <FormItem>
        <div class="text-right">
          <Button
            type="success"
            size="small"
            :disabled="!newTaskData.actionDate||!newTaskData.title||!newTaskData.assetId"
            :loading="btnSetLoading"
            @click="handleSubmit"
          >确认设置</Button>
        </div>

      </FormItem>
    </Form>
  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
import { companyMixin } from '@/assets/mixins/company'
// import { getStationByAssetIds } from '@/api/product/station'
import { getRimStationByAssetIds } from '@/api/rim/station'
import { setTempTaskitem, updateTempTaskitem } from '@/api/msp/demandV3'
export default {
  mixins: [sysMixins, companyMixin],
  data () {
    return {
      newTaskData: {
        assetId: null,
        domainId: null,
        taskitemType: 10,
        actionDate: '',
        taskId: null,
        title: '',
        extras: '',
        remarks: ''
      },
      ruleValidate: {
        actionDate: [{ required: true, message: ' ', trigger: 'change' }],
        assetId: [{ required: true, type: 'number', message: ' ', trigger: 'change' }],
        domainId: [{ required: true, type: 'number', message: ' ', trigger: 'change' }],
        title: [{ required: true, type: 'string', message: ' ', trigger: 'blur' }]
      },
      stationArray: [],
      companyId: this.$store.getters.token.userInfo.companyId,
      btnSetLoading: false
    }
  },
  computed: {
    curTaskId () {
      return this.$store.state.task.curTaskId
    },
    opreateType () {
      return this.$store.state.task.opreateType
    },
    selectedTaskitemIdArray: {
      get () {
        return this.$store.state.task.selectedTaskitemIdArray
      },
      set (val) {
        this.$store.commit('set_selected_taskitemid_array', val)
      }
    }
  },
  created () {
    this.getCompanyAssetArray()
  },
  methods: {
    changeActionDate (date) {
      this.newTaskData.actionDate = date
    },
    getStationData () {
      if (this.newTaskData.assetId) {
        getRimStationByAssetIds({ assetIds: JSON.stringify([this.newTaskData.assetId]) }).then(res => {
          if (res && !res.errcode) {
            this.stationArray = res
          } else {
            this.stationArray = []
          }
        })
      }
    },
    handleSubmit () {
      this.$refs.newTaskData.validate(valid => {
        if (valid) {
          this.$Modal.confirm({
            title: '操作提示',
            content: '确认信息无误并提交？',
            onOk: () => {
              this.btnSetLoading = true
              this.newTaskData.taskId = this.curTaskId

              if (this.opreateType === 1) {
                // 新增
                setTempTaskitem(this.newTaskData).then(res => {
                  this.btnSetLoading = false
                  if (res && res.errcode === 0) {
                    this.newTaskData.taskId = null
                    this.$Notice.success({ desc: '操作成功' })
                    this.$store.dispatch('getTaskData')
                  }
                })
              } else {
                // 更改
                this.newTaskData.taskitemId = this.selectedTaskitemIdArray[0]
                updateTempTaskitem(this.newTaskData).then(res => {
                  this.btnSetLoading = false
                  if (res && res.errcode === 0) {
                    this.newTaskData.taskitemId = null
                    this.$Notice.success({ desc: '操作成功' })
                    this.selectedTaskitemIdArray = []
                    this.$store.commit('set_temp_selected_item', null)
                    this.$store.dispatch('getTaskitemData')
                  }
                })
              }
              this.setShowLeftBlock(false)
              this.newTaskData.actionDate = ''
              this.newTaskData.assetId = null
              this.newTaskData.title = ''
              this.newTaskData.remarks = ''
            }
          })
        }
      })
    }
  }
}
</script>
